import { Form, Input, Button } from "antd"
import { UserOutlined, LockOutlined } from "@ant-design/icons"
import { apiUser } from "api"
import { setCookieAuth } from "lib/cookie"

const LoginForm = () => {
  // Callback function when the form is submitted
  const onFinish = async (values) => {
    try {
      // Make a login API call using the provided credentials
      const { data } = await apiUser.login(values)

      // Extract access_token and refresh_token from the API response
      const { access_token, refresh_token } = data

      // Set authentication cookies with the obtained tokens
      setCookieAuth({ access_token, refresh_token })

      // Reload the window to apply authentication changes
      window.location.reload()
    } catch (error) {}
  }

  // Function to perform a demo login with predefined credentials
  function onLogin() {
    // Hardcoded demo credentials for testing
    const values = {
      username: "leomessi3051996@gmail.com",
      password: "123456"
    }

    // Call the onFinish function with the demo credentials
    onFinish(values)
  }

  // Check if the environment is a demo environment
  const isDemo = !window.location.origin.includes("com")

  return (
    <div className="flex bg-gray-50 dark:bg-gray-900 flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <div className="w-full bg-white p-6 rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700">
        <Form name="normal_login" initialValues={{ remember: true }} onFinish={onFinish}>
          {/* Input field for the username */}
          <Form.Item name="username" rules={[{ required: true, message: "username" }]}>
            <Input
              size="large"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Tài khoản"
            />
          </Form.Item>

          {/* Input field for the password */}
          <Form.Item name="password" rules={[{ required: true, message: "Password" }]}>
            <Input
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              className="mb-8"
              placeholder="Password"
            />
          </Form.Item>

          {/* Demo login button (visible only in demo environment) */}
          {isDemo && (
            <div className="flex justify-center p-2 gap-2">
              <Button onClick={onLogin}>DemoLogin</Button>
            </div>
          )}

          {/* Submit button */}
          <Form.Item>
            <Button type="primary" size="large" htmlType="submit" className="w-full">
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default LoginForm
