import { RESOURCE_KEY } from "lib/Const"
import { lazy } from "react"
const UserProfile = lazy(() => import("pages/UserProfile"))
const Security = lazy(() => import("pages/UserProfile/Security"))
const PersonalUsageHistory = lazy(() => import("pages/UserProfile/PersonalUsageHistory"))
const Notification = lazy(() => import("pages/Notification"))
const DetailNotification = lazy(() => import("pages/Notification/DetailNotification"))
const Service = lazy(() => import("pages/Service"))
const UpdateService = lazy(() => import("pages/Service/UpdateService"))
const FunctionSetting = lazy(() => import("pages/FunctionSetting"))

const UpdateFunctionSetting = lazy(() => import("pages/FunctionSetting/UpdateFunctionSetting"))
const ApprovalSetting = lazy(() => import("pages/ApprovalSetting"))
const UpdateApproval = lazy(() => import("pages/ApprovalSetting/UpdateApproval"))
const ApprovalList = lazy(() => import("pages/ApprovalList"))
// const Dashboard = lazy(() => import("pages/Dashboard"))
const TopPage = lazy(() => import("pages/TopPage"))
const ActivityLogs = lazy(() => import("pages/ActivityLogs"))
const ViewLog = lazy(() => import("pages/ActivityLogs/ViewLog"))
const VehicleManagement = lazy(() => import("pages/VehicleManagement"))
const UpdateVehicleManagement = lazy(
  () => import("pages/VehicleManagement/UpdateVehicleManagement")
)
const DriverManagement = lazy(() => import("pages/DriverManagement"))
const UpdateDriver = lazy(() => import("pages/DriverManagement/UpdateDriver"))
const DriverInfo = lazy(() => import("pages/DriverManagement/DriverInfo"))
const VehicleGroup = lazy(() => import("pages/VehicleGroup"))
const UpdateVehicleGroup = lazy(() => import("pages/VehicleGroup/UpdateVehicleGroup"))
const OperationLog = lazy(() => import("pages/OperationLog"))
const ChauffeurConnect = lazy(() => import("pages/ChauffeurConnect"))
const ViewChauffuerConnect = lazy(() => import("pages/ChauffeurConnect/ViewChauffuerConnect"))
const ListProject = lazy(() => import("pages/ListProject"))
const Department = lazy(() => import("pages/Department"))
const UpdateDepartment = lazy(() => import("pages/Department/UpdateDepartment"))
const BusinessHours = lazy(() => import("pages/BusinessHours"))
const UpdateBusinessHours = lazy(() => import("pages/BusinessHours/UpdateBusinessHours"))
const OverTimeRules = lazy(() => import("pages/OverTimeRules"))
const UpdateOverTimeRule = lazy(() => import("pages/OverTimeRules/UpdateOverTimeRule"))
const Holidays = lazy(() => import("pages/Holidays"))
const UpdateHoliday = lazy(() => import("pages/Holidays/UpdateHoliday"))
const EmergencyContact = lazy(() => import("pages/EmergencyContact"))
const ReportList = lazy(() => import("pages/ReportList"))
const ExpenseCategory = lazy(() => import("pages/ExpenseCategory/ExpenseCategory"))
const UpdateExpenseCategory = lazy(() => import("pages/ExpenseCategory/UpdateExpenseCategory"))
const ReportExpenseUpdate = lazy(() => import("pages/ReportList/ReportExpenseUpdate"))
const ReportAbsenceUpdate = lazy(() => import("pages/ReportList/ReportAbsenceUpdate"))
const VehicleDepot = lazy(() => import("pages/VehicleDepot"))
const UpdateVehicleDepot = lazy(() => import("pages/VehicleDepot/UpdateVehicleDepot"))
const PassengerManagement = lazy(() => import("pages/PassengerManagement"))
const ViewVehiclePage = lazy(() => import("pages/VehicleManagement/ViewVehiclePage"))
const UpdatePassengerManagement = lazy(
  () => import("pages/PassengerManagement/UpdatePassengerManagement")
)
const ExportsManage = lazy(() => import("pages/ExportsManage"))
const CreateRide = lazy(() => import("pages/ChauffeurConnect/Ride/CreateRide"))
const CreateMeeting = lazy(() => import("pages/ChauffeurConnect/Meeting/CreateMeeting"))
const CreateAirport = lazy(() => import("pages/ChauffeurConnect/Airport/CreateAirport"))
const CreateRental = lazy(() => import("pages/ChauffeurConnect/Rental/CreateRental"))
const CreatePackage = lazy(() => import("pages/ChauffeurConnect/Package/CreatePackage"))
const ChauffuerConnectSetting = lazy(() => import("pages/ChauffeurConnectSetting"))
const ViewPassengerManagement = lazy(
  () => import("pages/PassengerManagement/ViewPassengerManagement")
)
const ChaffeurConnectCalander = lazy(() => import("pages/ChauffeurConnect/ChaffeurConnectCalander"))
const SendNotification = lazy(() => import("pages/SendNotification"))
const UpdateEndUserNotification = lazy(
  () => import("pages/SendNotification/UpdateEndUserNotification")
)
const UpdatePortalUserNotification = lazy(
  () => import("pages/SendNotification/UpdatePortalUserNotification")
)
const SegmentNotification = lazy(() => import("pages/SegmentNotification"))
const UpdateSegmentNotification = lazy(
  () => import("pages/SegmentNotification/UpdateSegmentNotification")
)

//____ListPage
function pageList(__role) {
  return [
    {
      Element: UserProfile,
      path: "/profile",
      code: "PROFILE_CONTROLLER",
      resource: RESOURCE_KEY.ROOT_BYPASS
    },
    {
      Element: Security,
      path: "/profile/security",
      code: "PROFILE_CONTROLLER",
      resource: RESOURCE_KEY.ROOT_BYPASS
    },
    {
      Element: PersonalUsageHistory,
      path: "/profile/personal_data_history",
      code: "PROFILE_CONTROLLER",
      resource: RESOURCE_KEY.ROOT_BYPASS
    },
    {
      Element: Notification,
      path: "/notification",
      code: "NOTIFICATION_CONTROLLER",
      resource: RESOURCE_KEY.ROOT_BYPASS
    },
    {
      Element: DetailNotification,
      path: "/notification/:id",
      code: "NOTIFICATION_CONTROLLER",
      resource: RESOURCE_KEY.ROOT_BYPASS
    },
    {
      path: "service_management",
      Element: Service,
      resource: RESOURCE_KEY.ROOT_SERVICE
    },
    {
      path: "service_management/action",
      Element: UpdateService,
      resource: RESOURCE_KEY.ROOT_SERVICE
    },
    {
      path: "functions_settings",
      pageName: "List Organization",
      Element: FunctionSetting,
      resource: RESOURCE_KEY.ROOT_FUNCTION_SETTING
    },
    {
      path: "functions_settings/service/:organization_id",
      Element: UpdateFunctionSetting,
      resource: RESOURCE_KEY.ROOT_FUNCTION_SETTING
    },
    {
      path: "approval/list_approval",
      Element: ApprovalList,
      resource: RESOURCE_KEY.ROOT_APPROVAL
    },
    {
      path: "approval/approval_setting/action",
      Element: UpdateApproval,
      resource: RESOURCE_KEY.ROOT_APPROVAL_SETTING
    },

    {
      path: "approval/approval_setting",
      pageId: "abc",
      Element: ApprovalSetting,
      resource: RESOURCE_KEY.ROOT_APPROVAL_SETTING
    },
    {
      path: "driver_management",
      Element: DriverManagement,
      resource: RESOURCE_KEY.ROOT_DRIVER
    },
    {
      path: "driver_management/action",
      Element: UpdateDriver,
      resource: RESOURCE_KEY.ROOT_DRIVER
    },
    {
      path: "driver_management/info",
      Element: DriverInfo,
      resource: RESOURCE_KEY.ROOT_DRIVER
    },
    {
      path: "/device_management/",
      Element: OperationLog,
      resource: RESOURCE_KEY.ROOT_VEHICLE
    },

    {
      path: "projects",
      Element: ListProject,
      resource: RESOURCE_KEY.ROOT_PROJECT_RESOURCE
    },

    // {
    //   path: "approval/approval_setting/action",
    //   Element: UpdateApproval,
    //   resource: RESOURCE_KEY.ROOT_APPROVAL_SETTING
    // },
    //
    // {
    //   path: "approval/approval_setting",
    //   pageId: "abc",
    //   Element: ApprovalSetting,
    //   resource: RESOURCE_KEY.ROOT_APPROVAL_SETTING
    // },
    {
      path: "activity_logs",
      Element: ActivityLogs,
      resource: RESOURCE_KEY.ROOT_BYPASS
    },
    {
      path: "activity_logs/:log_id",
      Element: ViewLog,
      resource: RESOURCE_KEY.ROOT_BYPASS
    },
    {
      path: "/",
      Element: TopPage,
      resource: RESOURCE_KEY.ROOT_BYPASS
    },
    {
      path: "/top",
      Element: TopPage,
      resource: RESOURCE_KEY.ROOT_BYPASS
    },
    {
      path: "/vehicle/management",
      Element: VehicleManagement,
      resource: RESOURCE_KEY.ROOT_VEHICLE
    },
    {
      path: "vehicle/management/action",
      Element: UpdateVehicleManagement,
      resource: RESOURCE_KEY.ROOT_VEHICLE
    },
    {
      path: "vehicle/management/view",
      Element: ViewVehiclePage,
      resource: RESOURCE_KEY.ROOT_VEHICLE
    },
    {
      path: "/vehicle/group",
      Element: VehicleGroup,
      resource: RESOURCE_KEY.ROOT_VEHICLE_GROUP
    },
    {
      path: "/vehicle/group/action",
      Element: UpdateVehicleGroup,
      resource: RESOURCE_KEY.ROOT_VEHICLE_GROUP
    },
    {
      path: "/vehicle/depot",
      Element: VehicleDepot,
      resource: RESOURCE_KEY.ROOT_VEHICLE_DEPOT
    },
    {
      path: "/vehicle/depot/action",
      Element: UpdateVehicleDepot,
      resource: RESOURCE_KEY.ROOT_VEHICLE_DEPOT
    },
    {
      path: "/work/report",
      Element: ReportList,
      resource: RESOURCE_KEY.ROOT_REPORT
    },
    {
      path: "/work/report/expense/action",
      Element: ReportExpenseUpdate,
      resource: RESOURCE_KEY.ROOT_REPORT
    },
    {
      path: "/work/report/absence/action",
      Element: ReportAbsenceUpdate,
      resource: RESOURCE_KEY.ROOT_REPORT
    },
    {
      path: "/work/expense",
      Element: ExpenseCategory,
      resource: RESOURCE_KEY.ROOT_EXPENSE_CATEGORY
    },
    {
      path: "/work/expense/action",
      Element: UpdateExpenseCategory,
      resource: RESOURCE_KEY.ROOT_EXPENSE_CATEGORY
    },
    {
      path: "/chauffeur-connect/activity_list",
      Element: ChauffeurConnect,
      resource: RESOURCE_KEY.ROOT_CC_ACTIVITY
    },
    {
      path: "/chauffeur-connect/activity_list/action",
      Element: ViewChauffuerConnect,
      resource: RESOURCE_KEY.ROOT_CC_ACTIVITY
    },
    {
      path: "/chauffeur-connect/activity_list/calendar",
      Element: ChaffeurConnectCalander,
      resource: RESOURCE_KEY.ROOT_CC_ACTIVITY
    },
    {
      path: "/chauffeur-connect/activity_list/ride/action",
      Element: CreateRide,
      resource: RESOURCE_KEY.ROOT_CC_ACTIVITY
    },
    {
      path: "/chauffeur-connect/activity_list/meeting/action",
      Element: CreateMeeting,
      resource: RESOURCE_KEY.ROOT_CC_ACTIVITY
    },
    {
      path: "/chauffeur-connect/activity_list/airport/action",
      Element: CreateAirport,
      resource: RESOURCE_KEY.ROOT_CC_ACTIVITY
    },
    {
      path: "/chauffeur-connect/activity_list/rental/action",
      Element: CreateRental,
      resource: RESOURCE_KEY.ROOT_CC_ACTIVITY
    },
    {
      path: "/chauffeur-connect/activity_list/package/action",
      Element: CreatePackage,
      resource: RESOURCE_KEY.ROOT_CC_ACTIVITY
    },
    {
      path: "/chauffeur-connect/setting",
      Element: ChauffuerConnectSetting,
      resource: RESOURCE_KEY.ROOT_CC_ORGANIZATION_SETTING
    },
    {
      path: "/company-setting/department-management",
      Element: Department,
      resource: RESOURCE_KEY.ROOT_DEPARTMENT
    },
    {
      path: "/company-setting/department-management/action",
      Element: UpdateDepartment,
      resource: RESOURCE_KEY.ROOT_DEPARTMENT
    },
    {
      path: "/company-setting/business-hours",
      Element: BusinessHours,
      resource: RESOURCE_KEY.ROOT_BUSSINESS_HOUR
    },
    {
      path: "/company-setting/business-hours/action",
      Element: UpdateBusinessHours,
      resource: RESOURCE_KEY.ROOT_BUSSINESS_HOUR
    },
    {
      path: "/company-setting/overtime-rules",
      Element: OverTimeRules,
      resource: RESOURCE_KEY.ROOT_OVERTIME_RULE
    },
    {
      path: "/company-setting/overtime-rules/action",
      Element: UpdateOverTimeRule,
      resource: RESOURCE_KEY.ROOT_OVERTIME_RULE
    },
    {
      path: "/company-setting/holidays",
      Element: Holidays,
      resource: RESOURCE_KEY.ROOT_HOLIDAY
    },
    {
      path: "/company-setting/holidays/action",
      Element: UpdateHoliday,
      resource: RESOURCE_KEY.ROOT_HOLIDAY
    },
    {
      path: "/company-setting/emergency-contact",
      Element: EmergencyContact,
      resource: RESOURCE_KEY.ROOT_EMERGENCY_CONTACT
    },
    {
      path: "/passenger-management",
      Element: PassengerManagement,
      resource: RESOURCE_KEY.ROOT_PASSENGER
    },
    {
      path: "/passenger-management/action",
      Element: UpdatePassengerManagement,
      resource: RESOURCE_KEY.ROOT_PASSENGER
    },
    {
      path: "/passenger-management/info",
      Element: ViewPassengerManagement,
      resource: RESOURCE_KEY.ROOT_PASSENGER
    },
    {
      path: "/exports",
      Element: ExportsManage,
      resource: RESOURCE_KEY.ROOT_BYPASS
    },
    {
      path: "/notification/send-notification",
      Element: SendNotification,
      resource: RESOURCE_KEY.ROOT_SEND_NOTIFICATION
    },
    {
      path: "/notification/send-notification/action",
      Element: UpdateEndUserNotification,
      resource: RESOURCE_KEY.ROOT_SEND_NOTIFICATION
    },
    // {
    //   path: "/notification/send-notification/portal-user/action",
    //   Element: UpdatePortalUserNotification,
    //   resource: RESOURCE_KEY.ROOT_SEND_NOTIFICATION
    // },
    {
      path: "/notification/segment",
      Element: SegmentNotification,
      resource: RESOURCE_KEY.ROOT_SEND_NOTIFICATION
    },
    {
      path: "/notification/segment/action",
      Element: UpdateSegmentNotification,
      resource: RESOURCE_KEY.ROOT_SEND_NOTIFICATION
    } //next_component
  ]
}

export default pageList
