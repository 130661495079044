import { ENUMS_CONFIG } from "./Const"

const expireDay = 1

function setLocalExpire(key, value, day) {
  const now = new Date()
  const item = {
    value: JSON.stringify(value),
    expiry: now.getTime() + day * 86400000
  }
  localStorage.setItem(key, JSON.stringify(item))
}

function getLocalExpire(key) {
  const itemStr = localStorage.getItem(key)
  if (!itemStr) return null
  const item = JSON.parse(itemStr)
  return item.value
}

export function setBaseDataLocal(baseData) {
  setLocalExpire("baseData", baseData, expireDay)
}

function checkExpireToken() {
  const itemStr = localStorage.getItem("baseData")
  if (!itemStr) return null
  const item = JSON.parse(itemStr)
  const now = new Date()
  if (now.getTime() > item.expiry) {
    removeAuthLocal()
  }
}

export function getBaseDataLocal() {
  checkExpireToken()
  const baseData = getLocalExpire("baseData")
  if (baseData) {
    return JSON.parse(baseData)
  } else {
    return {}
  }
}

export function removeAuthLocal() {
  localStorage.clear()
}

export function setConfig(type, data) {
  localStorage.setItem(type, JSON.stringify(data))
}

export function getConfig(type) {
  const data = localStorage.getItem(type)
  if (data) {
    return JSON.parse(data)
  } else {
    return []
  }
}
export function setLocalProject(v) {
  if (v?.id) {
    localStorage.setItem("project", JSON.stringify(v))
  } else {
    localStorage.setItem("project", {})
  }
}
export function getLocalProject() {
  const data = localStorage.getItem("project")
  if (data) {
    return JSON.parse(data)
  } else {
    return {}
  }
}

export function setEnums(enums) {
  let saveEnums = {}
  if (enums && Object.keys(enums)?.length) {
    saveEnums = enums
  }
  localStorage.setItem("enums", JSON.stringify(saveEnums))
}
export function getEnums() {
  const enums = ENUMS_CONFIG
  if (enums) return enums
  return {}
}
