import { ConfigProvider, notification } from "antd"
import { StyleProvider } from "@ant-design/cssinjs"
import useStore, { StoreProvider } from "components/ui/Context"
import Routes from "components/Router"
import "./utils/i18n"
import "./App.scss"
import { useEffect } from "react"
import { changeLanguage } from "./utils/i18n"
import { getI18nextLng } from "lib/cookie"
import { apiCompany } from "api"

notification.config({
  top: 0,
  duration: 3
})
function App() {
  const { access_token } = useStore()

  useEffect(() => {
    async function fetch(params) {
      try {
        const localization = await apiCompany.getSettings()
        localStorage.setItem("localization", JSON.stringify(localization?.data))
      } catch (error) {}
    }

    if (access_token) {
      changeLanguage(getI18nextLng())
      fetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#1C3F38",
          borderRadius: 4,
          fontFamily: "Inter"
          // Alias Token
          // colorBgContainer: "#f0f2f5"
        }
      }}
    >
      <StyleProvider hashPriority="high">
        <StoreProvider>
          <Routes />
          <div id="modal-root"></div>
        </StoreProvider>
      </StyleProvider>
    </ConfigProvider>
  )
}

export default App

const data = {
  sim_status: {
    0: "Inactive",
    1: "Active"
  },
  sim_connected_status: {
    0: "Disconnect",
    1: "Connecting",
    2: "Connected"
  },
  device_status: {
    0: "InStock",
    1: "Sent",
    2: "Delivered",
    3: "Returning"
  },
  receive_device_status: {
    1: "In_transit",
    2: "Received",
    3: "Returning"
  },
  vehicle_status: {
    0: "InStock",
    1: "Sent",
    2: "Delivered",
    3: "Returning"
  },
  organization_type: {
    1: "Tenant",
    2: "ServiceProvider",
    3: "Hybrid"
  },
  history_action: {
    0: "Create",
    1: "Update",
    2: "Update_status"
  }
}
let list = Object.keys(data)
let obj = {}
for (let i = 0; i < list.length; i++) {
  const keyy = list[i]
  // obj[keyy] =
  const item = data[keyy]
  let newList = Object.keys(item).map((j) => {
    return {
      label: item[j],
      value: j
    }
  })
  obj[keyy] = newList
}
