import Collapse from "components/Collapse"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { NavLink, useLocation, useNavigate } from "react-router-dom"

export default function Navbar({ items, isNav }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const location = useLocation()

  useEffect(() => {
    if (isNav === "left-0") {
      if (document.body.style.overflow !== "hidden") {
        document.body.style.overflow = "hidden"
      }
    }
    return () => {
      document.body.style.overflow = null
      document.body.style.width = null
    }
  }, [isNav])
  const wapperClass = isNav ? "show__sider" : "hide__sider"

  return (
    <div
      className={`__bg z-10 sticky text-slate-400 h-screen border-r border-slate-700 top-0 shadow ease-in-out duration-300 ${wapperClass}`}
    >
      <div
        className={`gap-3 border-b justify-center py-3 border-slate-700 px-6 h-14 flex text-xl font-bold items-center text-slate-50`}
      >
        <img
          src={isNav ? "/logo.png" : "/logo-small.png"}
          alt="logo"
          className={`${isNav ? "h-full" : "h-4/5"} cursor-pointer logo`}
          onClick={() => navigate("/top")}
        />
      </div>
      <div className="px-2">
        {isNav && (
          <div className="bg-gray-100 p-2 text-center text-black rounded font-medium text-lg">
            {t("1_10075")}
          </div>
        )}
      </div>
      <div className={isNav ? "overflow-y-auto __scroll" : ""}>
        <div className="flex flex-col py-4 text-sm">
          <ul className="space-y-2">
            {items.map((item) => {
              const { url, title, icon, children } = item
              if (children && children.length > 0) {
                return <MenuItem t={t} {...item} key={url} location={location} isNav={isNav} />
              } else {
                const activeClassName = location.pathname.includes(url)
                  ? "bg-white text-black"
                  : "text-gray-100"
                return (
                  <li key={url} className="px-4 mb-2">
                    <NavLink to={url}>
                      <div className={`${activeClassName} menu__item relative`}>
                        {icon}
                        <span className="text-ellipsis flex-auto overflow-hidden whitespace-nowrap">
                          {t(title)}
                        </span>
                        {!isNav && (
                          <div className="tooltip_title_not_sub hidden absolute left-[72px] bg-gray-800 p-3 rounded top-0 w-max">
                            {t(title)}
                          </div>
                        )}
                      </div>
                    </NavLink>
                  </li>
                )
              }
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

function MenuItem({ title, icon, url, children, location, isNav, closeSidebar, t }) {
  const [isOpen, setOpen] = useState(children.find((i) => location.pathname.includes(i.url)))
  function onTogle() {
    setOpen((c) => !c)
  }
  const activeClassname = location.pathname.includes(url) ? "bg-white text-black" : "text-gray-100"
  const classIsDown = isOpen ? "rotate-180" : "rotate-0"

  return (
    <div className="sub__menu relative">
      <li className="px-4">
        <div
          onClick={onTogle}
          className={`${activeClassname} rounded cursor-pointer hover:bg-white/10
        h-11 px-4 flex items-center justify-between gap-4`}
        >
          <div className="flex w-full items-center gap-4">
            {icon}
            <span className="text-ellipsis flex-auto overflow-hidden whitespace-nowrap">
              {t(title)}
            </span>
          </div>
          {isNav && (
            <div className={`${classIsDown} flex items-center transition-all duration-300`}>
              {icDown}
            </div>
          )}
        </div>
      </li>
      <div className="h-[1px]"></div>
      <Collapse isOpen={isOpen} isShow={isNav}>
        <ul className="p-2 pl-4 space-y-2 bg-white/10 rounded">
          {children.map((item) => {
            return (
              <li key={`${item.url}-${url}`}>
                <NavLink
                  to={`${item.url}`}
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center bg-white text-black gap-4 py-1 px-4 rounded __active__sub__menu"
                      : "flex items-center text-slate-100 gap-4 py-1 px-4 rounded"
                  }
                >
                  <span className="text-ellipsis flex items-center gap-2 flex-auto overflow-hidden whitespace-nowrap">
                    {item?.icon}
                    {t(item.title)}
                  </span>
                </NavLink>
              </li>
            )
          })}
        </ul>
      </Collapse>
    </div>
  )
}

const icDown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-4 w-4"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
  </svg>
)
