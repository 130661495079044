import ClientChauffeur from "../client/ClientChauffeur"
import ClientIOt from "../client/ClientIot"

import version from "../version"
const resource = version + "/client/activity"
const resourceFavorite = version + "/client/favorite-location"

function gets(params) {
  return ClientChauffeur("root_service.service_path_create").get(`${resource}/list/`, { params })
}

function getsElastic(params) {
  return ClientChauffeur("root_service.service_path_create").get(`${resource}/es-list/`, { params })
}

function getsElasticByCalendar(params) {
  return ClientChauffeur("root_service.service_path_create").get(`${resource}/es-calendar/`, {
    params
  })
}

function get(id) {
  return ClientChauffeur("root_service.service_path_create").get(`${resource}/retrieve/${id}/`)
}
function createActivity(data) {
  return ClientChauffeur("root_service.service_path_create").post(`${resource}/create/`, data)
}
function updateActivity(id, data) {
  return ClientChauffeur("root_service.service_path_create").put(
    `${resource}/retrieve/${id}/`,
    data
  )
}
function removeActivity(id, data) {
  return ClientChauffeur("root_service.service_path_create").delete(
    `${resource}/retrieve/${id}/`,
    data
  )
}

function exportActivity(data) {
  return ClientChauffeur("root_service.service_path_create").post(`${resource}/export/`, data)
}
function getFlights(params) {
  return ClientChauffeur("root_service.service_path_create").get(`${resource}/find-flight/`, {
    params
  })
}
function getFlightsLocation(params) {
  return ClientChauffeur("root_service.service_path_create").get(`${resource}/find-airport/`, {
    params
  })
}
function findVehicle(data) {
  return ClientChauffeur("root_service.service_path_create").post(`${resource}/find-vehicle/`, data)
}

function getDevicesPositions(data) {
  return ClientIOt("root_service.service_path_create").post(`api/v1/devices/positions`, data)
}

function getLog(params) {
  return ClientIOt("root_service.service_path_create").get(`${version}/devices_log`, {
    params
  })
}

function getFavorites(params) {
  return ClientChauffeur("root_service.service_path_create").get(`${resourceFavorite}/list/`, {
    params
  })
}
function getFavorite(id, params) {
  return ClientChauffeur("root_service.service_path_create").get(
    `${resourceFavorite}/retrieve/${id}`,
    {
      params
    }
  )
}

function createFavorite(data) {
  return ClientChauffeur("root_service.service_path_create").post(
    `${resourceFavorite}/create/`,
    data
  )
}
function updateFavorite(id, data) {
  return ClientChauffeur("root_service.service_path_create").put(
    `${resourceFavorite}/retrieve/${id}/`,
    data
  )
}

function removeFavorite(id, data) {
  return ClientChauffeur("root_service.service_path_create").delete(
    `${resourceFavorite}/retrieve/${id}/`,
    data
  )
}

function getPickUpPoi(params) {
  return ClientChauffeur("root_service.service_path_create").get(
    `${version}/client/poi-pickup-dropoff-point/list/`,
    { params }
  )
}

const api = {
  gets,
  getsElastic,
  getsElasticByCalendar,
  get,
  exportActivity,
  createActivity,
  updateActivity,
  getFlights,
  findVehicle,
  getLog,
  getFavorites,
  getFavorite,
  createFavorite,
  updateFavorite,
  removeFavorite,
  removeActivity,
  getFlightsLocation,
  getPickUpPoi,
  getDevicesPositions
}
export default api
