import Client from "../client/ClientChauffeur"
import version from "../version"
const resource = version + "/internal/services"

function gets(params) {
  return Client("root_service.service_path_view").get(`${resource}/`, { params })
}
function getExternalServices() {
  return Client("root_service.service_path_view").get(`${version}/external-services/`)
}
function get(id) {
  return Client("root_service.service_path_detail").get(`${resource}/${id}/`)
}
function create(data) {
  return Client("root_service.service_path_create").post(`${resource}/`, data)
}
function update(id, data) {
  return Client("root_service.service_path_update").put(`${resource}/${id}/`, data)
}
function remove(id) {
  return Client("root_service.service_path_delete").delete(`${resource}/${id}/`)
}

function updateMutil(data) {
  return Client("root_service.service_path_update").put(`${resource}/change-status/multiple/`, data)
}
function removeMutil(data) {
  return Client("root_service.service_path_delete").put(`${resource}/delete/multiple/`, data)
}

const api = {
  gets,
  getExternalServices,
  get,
  create,
  update,
  remove,
  updateMutil,
  removeMutil
}
export default api
