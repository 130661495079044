import Client from "../client/ClientControlPlane"
import version from "../version"

const resource = version + "/internal/applications"

function gets(params) {
  return Client.get(`${resource}/`, { params })
}
function get(id) {
  return Client.get(`${resource}/${id}/`)
}
function getAuthCode() {
  return Client().get(`${version}/internal/application/me/`)
}
function create(data) {
  return Client.post(`${resource}/`, data)
}
function update(id, data) {
  return Client.put(`${resource}/${id}/`, data)
}
function remove(id, params) {
  return Client.delete(`${resource}/${id}/`, { params })
}
function updateOrder(data) {
  return Client.put(`${resource}/reorder/`, data)
}

const api = {
  gets,
  get,
  getAuthCode,
  create,
  update,
  remove,
  updateOrder
}
export default api
