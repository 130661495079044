import Client from "../client/ClientChauffeur"
import version from "../version"
const resource = version + "/approval_settings"

function getApprovalConfirm(params) {
  return Client("root_approval.approval_path_view").get(`${version}/approvals/`, {
    params
  })
}
function getDetailApproval(id) {
  return Client("root_approval.approval_path_view").get(`${version}/approvals/${id}/`)
}

function gets(params) {
  return Client("root_approval_setting.approval_setting_path_view").get(`${resource}/`, {
    params
  })
}
function get(id) {
  return Client("root_approval_setting.approval_setting_path_detail").get(`${resource}/${id}/`)
}

function create(data) {
  return Client("root_approval_setting.approval_setting_path_create").post(`${resource}/`, data)
}
function update(id, data) {
  return Client("root_approval_setting.approval_setting_path_update").put(
    `${resource}/${id}/`,
    data
  )
}
function remove(id) {
  return Client("root_approval_setting.approval_setting_path_delete").delete(`${resource}/${id}/`)
}

function getApprovalObjects(params) {
  return Client("root_approval_setting.approval_setting_path_view").get(
    `${version}/approvals_settings/objects/`,
    { params }
  )
}

function getApprovalServices(params) {
  return Client("root_approval_setting.approval_setting_path_view").get(
    `${version}/approvals_settings/services/`,
    { params }
  )
}

function updateApprovalConfirm(id, data) {
  return Client("root_approval_setting.approval_setting_path_update").put(
    `${version}/approvals/${id}/change_approval_status/`,
    data
  )
}

function requestApprovalSystem(data) {
  return Client("root_approval.approval_path_create").post(`${version}/approvals/create/`, data)
}

function getApprovalSettingDetail(code) {
  return Client("root_approval_setting.approval_setting_path_detail").get(
    `${version}/approval_settings/${code}/`
  )
}

const api = {
  gets,
  get,
  getDetailApproval,
  create,
  update,
  remove,
  getApprovalObjects,
  getApprovalServices,
  getApprovalConfirm,
  updateApprovalConfirm,
  requestApprovalSystem,
  getApprovalSettingDetail
}
export default api
