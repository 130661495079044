import axios from "axios"
import createAuthRefreshInterceptor from "axios-auth-refresh"
import { notification } from "antd"
import { getCookieAuth, removeCookies } from "lib/cookie"
import { v4 as uuidv4 } from "uuid"
import { getBaseDataLocal, setBaseDataLocal } from "lib/localstorage"
import { getI18nextLng } from "lib/cookie"

const globalId = uuidv4()
const refreshAuthLogic = (failedRequest) =>
  axios
    .post(`${process.env.REACT_APP_IDM_URL}/api/v1/account/refresh/`, null, {
      headers: {
        access_token: getCookieAuth()?.access_token
      }
    })
    .then((tokenRefreshResponse) => {
      localStorage.setItem("accessToken", tokenRefreshResponse.data.token)

      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + tokenRefreshResponse.data.token
      return Promise.resolve()
    })
    .catch(() => {
      localStorage.clear()
      removeCookies()
      // axios.delete("/auth/register", { token: getRefreshToken() })
      window.location.href = "/login"
    })

export default function getInstanceAxios(
  baseAPI,
  resource,
  service_code,
  method,
  project_id,
  action,
  requestId
) {
  const application = JSON.parse(localStorage.getItem("application"))
  const instance = axios.create({
    // withCredentials: true,
    baseURL: baseAPI
  })

  instance.interceptors.request.use(
    function (config) {
      config.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Http-global-id": globalId,
        "Device-Id": uuidv4(),
        method: method || config.method,
        "X-App-Auth-Code": application?.app_auth_code
      }

      const oldToken = getBaseDataLocal()?.access_token
      const { access_token } = getCookieAuth()
      if (oldToken && oldToken !== access_token) {
        setBaseDataLocal({})
        window.location.href = "/login"
      }
      // if (access_token && service_code !== SERVICE_CODE.API_CHAUFFEUR)
      config.headers["Authorization"] = `Bearer ${access_token}`
      config.headers.resource = resource
      config.headers.service = service_code
      if (project_id) config.headers["project-id"] = project_id
      if (action) config.headers["action"] = action
      if (requestId) config.headers["request-id"] = requestId
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    function (response) {
      try {
        if (response.status >= 200 && response.status < 300) return response.data
        return Promise.reject(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async function (error) {
      if (error.response) {
        const { response } = error
        if (response.status === 401) removeCookies()
        const data = response.data
        const languageCode = getI18nextLng()
        const amsErrorMsgs = JSON.parse(localStorage.getItem("errorMsg"))
        const messageAfterTranslate = getMessageAfterTranslate(data, languageCode, amsErrorMsgs)
        if (messageAfterTranslate) {
          notification.error({
            message: messageAfterTranslate
          })
        } else if (data && response.config.method !== "get" && response.status !== 500) {
          if (data.error_message) {
            notification.error({
              description:
                typeof data.error_message === "string"
                  ? data.error_message
                  : convertMessageError(data.error_message),
              duration: 3
            })
            return Promise.reject(data.error_message)
          }
          if (data.message) {
            notification.error({
              description: data?.message || data?.error_message
            })
            return
          }
          if (data && !data.message && Object.values(data).length > 0) {
            if (data.length > 0) {
              data.forEach((item) => {
                notification.error({
                  description: item,
                  duration: 3
                })
              })
            } else {
              Object.values(data).forEach((item) => {
                notification.error({
                  description: item?.length > 0 ? item[0] : "N/A",
                  duration: 3
                })
              })
            }
          } else {
            notification.error({
              description: data?.message || data?.error_message
            })
          }
        }
      }
      return Promise.reject(error)
    }
  )

  createAuthRefreshInterceptor(instance, refreshAuthLogic)
  return instance
}

function getMessageAfterTranslate(data, languageCode, amsErrorMsgs) {
  const amsErrorMsg = amsErrorMsgs?.find(
    (item) => item?.language_code === languageCode && item?.error_code === data?.error_code
  )
  return amsErrorMsg?.content || undefined
}

function convertMessageError(errors) {
  var str = []
  for (let i = 0; i < errors.length; i++) {
    if (typeof errors[i] === "string") {
      str.push(`${errors[i]}. `)
    } else {
      let k = Object.keys(errors[i])[0]
      str.push(k + " : " + errors[i][k])
    }
  }
  return str.map((i) => {
    return <div>{i}</div>
  })
}
