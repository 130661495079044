// import { SERVICE_CODE } from "lib/Const"
import getInstanceAxios from "utils/request"

const baseDomain = process.env.REACT_APP_API_GATEWAY_URL

export default function ClientChauffeur(resource, method, action, requestId) {
  const application = JSON.parse(localStorage.getItem("application"))
  const serviceCode = `${application?.organization?.code}-chauffeur-connect`
  const baseURL = `${baseDomain}/${serviceCode}`

  return getInstanceAxios(baseURL, resource, serviceCode, method, undefined, action, requestId)
}
